import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import AOS from 'aos';

import Seo from "../components/seo"
import Header from "../components/header"
import BlogArtigosComponent from "../components/blog-artigos";
import Footer from "../components/footer"


const RecursosFinanceiroPage = () => {

  useEffect(() => {
	 AOS.init()
  }, []);


  return (
	<main>
	  <Seo
		title="Gestão Financeira e Controle de Contas a Pagar e Receber | Sistema de Gestão Financeira 💵"
		description="O sistema financeiro do wEstoque vai te ajuda a fazer a gestão das contas a pagar e receber em tempo real, de forma simples e rápida. As emissões de NFC-e (cupom fiscal) e NF-e (Nota Fiscal Eletrônica) não possuem limitações. Use agora!"
		keywords={[
			`sistema financeiro`,
			`multifilais`,
			`contas a pagar`,
			`receber`,
			`boletos`,
			`plano de contas`,
			`controlar várias empresas`,
			`controle financeiro`,
			`emitir nota`,
			`emitir cupom fiscal`,
		]}
	  />

	  <Header />
 
	  <section style={{paddingTop: `100px`}} className="overflow-x-hidden">

		<div className="max-w-6xl mx-auto">

			<div className="py-8 md:py-15">
			
			<div className="grid gap-3 sm:gap-8">
				
							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

									<StaticImage
										src="../images/controlefinanceiro-topo-westoque.png"
										alt="Controle financeiro e contas a pagar e receber"
										placeholder="blurred"
										layout="fixed"
										quality={100}
										width={350}
										className="max-w-full mx-auto h-auto"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-center" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-12 md:pb-16 items-center">
										<h1 className="text-center text-5xl font-bold" >
                                        Controle Financeiro
										</h1>
										<br/>
										<p className="text-xl text-gray-700 mb-4">O nosso software de gestão financeira empresarial vai te ajuda a fazer a gestão em tempo real, de forma simples e rápida. Sem contar que ainda há redução de erros, pois o processo passa a ser automatizado. O wEstoque é um sistema de controle financeiro completo e totalmente online.</p>
										<br/>
										<a href="/usar" className="text-md font-bold text-westoque-400 hover:text-westoque-500 hover:underline">Teste Gratuitamente &#8594;</a>
									</div>
								</div>
							</div>



							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-28">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

									<StaticImage
											src="../images/recursos-contas-pagar-receber.png"
											alt="Contas à Pagar & Receber"
											placeholder="blurred"
											quality={100}
											className="w-full"
										/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left align-top" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-semibold text-westoque-400"><span className="md:hidden" role="img" aria-label="dinheiro emoji">💰</span> Contas à Pagar & Receber</h4>

											<div className="mt-1 text-md md:text-xl">
											Com nosso sistema financeiro web você pode fazer o controle de contas à pagar e receber online por data de emissão do documento, data do vencimento e pagamento da conta, favorecido e valor. Assim você terá o controle total do seu custo fixo/mensal e ainda poderá dividir por plano de compras e fazer a gestão de contas bancárias.
											</div>
										</div>
									</div> 
								</div>
							</div>


							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

										<StaticImage
											src="../images/recursos-controle-forma-pagamento.png"
											alt="Controle de Formas de Pagamento"
											placeholder="blurred"
											quality={100}
											className="w-full max-w-md"
										/>
										
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-400"><span className="md:hidden" role="img" aria-label="card emoji">💳</span> Controle de Formas de Pagamento</h4>

											<div className="mt-1 text-md md:text-xl">
											Gerencie de forma completa todas as formas de pagamento que sua empresa possui. Controle taxas de cartão de crédito e débito e fique por dentro de todas as informações importantes para deixar sua conta azul!
											</div>
										</div>
									</div>
								</div>
							</div>


							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">
									<StaticImage
										src="../images/recursos-salarios-comissoes.png"
										alt="Gestão de Salários & Comissões"
										placeholder="blurred"
										quality={100}
										className="w-full max-w-md"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left md:order-1" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-400"><span className="md:hidden" role="img" aria-label="comissao emoji">🥇</span> Gestão de Salários & Comissões</h4>

											<div className="mt-1 text-md md:text-xl">
											Com o wEstoque fica fácil no final do mês (ou no dia que quiser) tirar um relatório com as comissões que devem ser pagas à cada vendedor. Faça o pagamento e gestão de salários previsionando o período que desejar e pague seus funcionários de forma simples.
											</div>
										</div>
									</div>
								</div>
							</div>

                           
                            <div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

										<StaticImage
											src="../images/recursos-plano-de-contas.png"
											alt="Controle Completo do Plano de Contas"
											placeholder="blurred"
											quality={100}
											className="w-full max-w-md"
										/>
										
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-400"><span className="md:hidden" role="img" aria-label="contas emoji">📓</span> Controle Completo do Plano de Contas</h4>

											<div className="mt-1 text-md md:text-xl">
											Organize seus pagamentos separando por plano de contas. Além de facilitar as suas consultas de pagamentos o sistema gera relatórios que facilitam o dia-a-dia da sua empresa. Contrate um sistema que te proporciona uma ampla visão e controle dos recursos do seu negócio.
											</div>
										</div>
									</div>
								</div>
							</div>

                            <div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">
									<StaticImage
										src="../images/recursos-dre-detalhado.png"
										alt="DRE Detalhado"
										placeholder="blurred"
										quality={100}
										className="w-full max-w-md"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left md:order-1" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-400"><span className="md:hidden" role="img" aria-label="dre emoji">📈</span> DRE Detalhado</h4>

											<div className="mt-1 text-md md:text-xl">
											Esse recurso te ajuda a saber onde está ganhando e perdendo dinheiro com base nos dados financeiros da empresa. Além de considerar as receitas e despesas, o DRE também registra os custos operacionais, o valor pago em impostos, preço dos produtos ou serviços prestados e outras despesas gerais. Por fim, ele apresenta se a empresa teve lucro ou prejuízo em um determinado período.
											</div>
										</div>
									</div>
								</div>
							</div>

                            

							<div className="md:grid md:grid-cols-12 md:gap-6 items-center mt-36">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block" data-aos="fade-in">
				  					<StaticImage
										src="../images/call-pdv-farmacia.png"
										alt="Controle sua loja e empresa com o sistema wEstoque"
										placeholder="blurred"
										layout="fullWidth"
										quality={100}
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate text-center" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-6 md:pb-8">
										<h1 className="text-center text-5xl font-bold mb-2 p-1" >
											<span className="underline decoration-westoque-400">Software Completo</span> de controle de <span className="underline decoration-westoque-400">Vendas e Estoques</span> para sua <span className="underline decoration-sky-500">loja</span>.
										</h1> 
										<div className="text-xl text-gray-700 mb-2 w-full p-3">Tenha total controle de suas operações financeiras. Com o <b className="text-2xl">w<span className="text-westoque-400">Estoque</span></b> você controla tudo de um só lugar!</div>
									</div>
									<a className="max-w-xs font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-md text-white bg-westoque-400 hover:bg-westoque-500 hover:transition-transform hover:scale-110 shadow-md shadow-westoque-400/50 transition duration-150 ease-in-out" href="/usar ">EXPERIMENTE GRÁTIS</a>
								</div>
							</div>
							
						
							<BlogArtigosComponent />

						</div>
					</div>
				</div>
			</section>

	  <br/><br/>
	  <Footer />


	</main>
  )
}

export default RecursosFinanceiroPage
